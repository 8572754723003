import i18n from '@/i18n'

export const MARK_AS_TYPES = {
  LATE: 'late',
  FAILED: 'failed',
  COMPLETED: 'completed'
}
export const MARK_AS_OPTIONS = [
  { type: MARK_AS_TYPES.LATE, lateBy: 5 },
  { type: MARK_AS_TYPES.LATE, lateBy: 15 },
  { type: MARK_AS_TYPES.LATE, lateBy: 30 },
  { type: MARK_AS_TYPES.LATE, lateBy: 45 },
  { type: MARK_AS_TYPES.FAILED },
  { type: MARK_AS_TYPES.COMPLETED }
]

export function getOptionKeySuffix(option) {
  if (option.lateBy) {
    return `${option.type}_${option.lateBy}`
  }
  return option.type
}

export function getOptionLabel(option) {
  if (option.lateBy) {
    if (option.lateBy <= 30) {
      return i18n.t('tasks.markAs.late.lateBy', { lateBy: option.lateBy })
    }
    return i18n.t('tasks.markAs.late.veryLate')
  }
  return i18n.t(`tasks.markAs.${option.type}`)
}
