<template>
  <div class="tasks-page">
    <navbar v-if="navbarVisible"/>
    <div class="container">
      <div class="right-container">
        <task-type-filter
          v-model="selectedTaskType"
          :taskTypes="taskTypes"/>
        <mark-as-selector
          position="is-bottom-left"
          :disabled="selectedTasks.length === 0"
          @change="onMarkAs"/>
        <b-button
          class="is-primary"
          @click="showSearchModal">
          {{ $t('tasks.search.title') }}
        </b-button>
      </div>
      <div class="tabs">
        <ul>
          <li
            v-for="tab in tabs"
            :key="`page_tabs_${tab}`"
            :class="{'is-active': tab === selectedTab }">
            <a @click="selectTab(tab)">
              {{ $t(`tasks.tabs.${tab}`) }}
            </a>
          </li>
        </ul>
      </div>
      <tasks-table
        :tasks="selectedTabTasks"
        :selectedTasks.sync="selectedTasks"
        :isCompleted="selectedTab === TABS.COMPLETED"/>
    </div>
    <b-modal
      :active.sync="searchTasksModalActive"
      :fullScreen="true"
      animation>
      <tasks-search :tasks="tasks"/>
    </b-modal>
    <b-modal
      :active="taskDetailsModalActive"
      :fullScreen="true"
      animation
      @close="onCloseTaskDetails">
      <task-details
        v-if="selectedTask"
        :task="selectedTask"/>
    </b-modal>
    <b-loading :active="loadingOverlayVisible"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TASK_STATES } from '@js/constants'
import apiWithFiltersMixin from '@/mixins/api-with-filters-mixin'
import { formatDate } from '@js/utils'
import fulfilmentChannelMixin from '@/mixins/fulfilment-channel-mixin'
import markTasksAsMixin from '@/mixins/mark-tasks-as-mixin'

const MarkAsSelector = () => import('@components/MarkAsSelector')
const Navbar = () => import('@components/Navbar')
const TaskDetails = () => import('@components/TaskDetails')
const TaskTypeFilter = () => import('@components/TaskTypeFilter')
const TasksSearch = () => import('@components/TasksSearch')
const TasksTable = () => import('@components/TasksTable')

const TABS = {
  IN_PROGRESS: 'inProgress',
  COMPLETED: 'completed'
}

const TASK_TYPE = {
  ALL: 'all',
  DELIVERY: 'delivery',
  PICKUP: 'pickup'
}

export default {
  name: 'tasks-page',
  components: {
    MarkAsSelector,
    Navbar,
    TaskDetails,
    TaskTypeFilter,
    TasksSearch,
    TasksTable
  },
  mixins: [
    apiWithFiltersMixin,
    fulfilmentChannelMixin,
    markTasksAsMixin
  ],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      TABS,
      tabs: [
        TABS.IN_PROGRESS,
        TABS.COMPLETED
      ],
      selectedTab: TABS.IN_PROGRESS,
      taskTypes: [
        TASK_TYPE.ALL,
        TASK_TYPE.DELIVERY,
        TASK_TYPE.PICKUP
      ],
      selectedTaskType: TASK_TYPE.ALL,
      searchTasksModalActive: false,
      selectedTasks: [],
      taskDetailsModalActive: false,
      // render navbar only once, when not showing task details
      // to prevent navbar from affecting URL when loading task details
      navbarVisible: !this.id,
      // override mixin
      isErrorWatcher: true
    }
  },
  computed: {
    ...mapGetters('api', [
      'getLoading'
    ]),
    ...mapGetters('task', [
      'task',
      'tasks'
    ]),
    ...mapGetters('trip', [
      'trip'
    ]),
    loadingOverlayVisible() {
      return this.getLoading(
        'task/getTaskByIdentifier',
        'task/getTasks'
      )
    },
    selectedTask() {
      if (this.task && this.task.orderIdentifier === this.id) {
        return this.task
      }
      if (this.trip) {
        const task = this.trip.tasks.find(tripTask => tripTask.orderIdentifier === this.id)
        if (task) {
          return task
        }
      }
      return this.tasks.find(task => task.orderIdentifier === this.id)
    },
    selectedTabTasks() {
      const tasks = this.selectedTab === TABS.IN_PROGRESS ? this.incompleteTasks : this.completedTasks
      switch (this.selectedTaskType) {
        case TASK_TYPE.DELIVERY:
          return tasks.filter(task => !task.isPickup)
        case TASK_TYPE.PICKUP:
          return tasks.filter(task => task.isPickup)
      }
      return tasks
    },
    incompleteTasks() {
      return this.tasks.filter(task => !this.isTaskCompleted(task))
    },
    completedTasks() {
      return this.tasks.filter(this.isTaskCompleted)
    }
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        if (this.id) {
          this.setTaskOrderIdentifier(this.id)
          if (!this.task) {
            this.getTaskByIdentifierRequest(this.id)
          }
        }

        // to show close button in modal
        this.$nextTick(() => {
          this.taskDetailsModalActive = !!this.id
          if (!this.id) {
            this.navbarVisible = true
          }
        })
      }
    },
    selectedTab() {
      this.selectedTasks = []
    },
    selectedTaskType() {
      this.selectedTasks = []
    }
  },
  created() {
    this.resetTaskStore()
    this.resetTripStore()

    if (!this.id) {
      this.debounceApiRequest()
    }
  },
  methods: {
    ...mapActions('task', [
      'resetTaskStore',
      'getTaskByIdentifierRequest',
      'getTasksRequest',
      'setTaskOrderIdentifier'
    ]),
    ...mapActions('trip', [
      'resetTripStore'
    ]),
    // override method in mixin
    onReceived(data) {
      if (['Trip', 'Task'].includes(data.object)) {
        // TODO: check if will disrupt any user actions
        this.debounceRequest(this.getTasks)
      }
    },
    // override method in mixin
    apiRequest() {
      this.subscribeFulfilmentChannel()

      this.getTasks()
    },
    getTasks() {
      this.getTasksRequest({
        servingDate: formatDate(this.selectedDate),
        timeRangeStart: this.selectedTimeRange[0],
        timeRangeEnd: this.selectedTimeRange[1],
        hubIds: this.selectedHubIds,
        inProgress: this.selectedTab === TABS.IN_PROGRESS
      })
    },
    isTaskCompleted(task) {
      return [TASK_STATES.COMPLETED, TASK_STATES.FAILED].includes(task.state)
    },
    selectTab(tab) {
      this.selectedTab = tab
      this.getTasks()
    },
    onMarkAs(option) {
      const tasks = this.selectedTabTasks.filter(task => this.selectedTasks.includes(task.id))
      this.markTasksAs(tasks, option)
    },
    showSearchModal() {
      this.searchTasksModalActive = true
    },
    onCloseTaskDetails() {
      this.$router.push({
        name: 'tasks',
        params: {}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tasks-page {
  .container {
    margin-top: $space-l;

    @include touch {
      padding-right: $space-m;
      padding-left: $space-m;
    }

    .right-container {
      position: absolute;
      top: 0;
      right: 0;

      > :not(:first-child) {
        margin-left: $space-xs;
      }

      .button {
        @extend %body;
      }
    }

    .tabs {
      a {
        border-bottom-width: 4px;
      }

      li:not(.is-active) {
        a:not(:hover) {
          border-color: transparent;
        }
      }
    }
  }
}
</style>
