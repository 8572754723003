import { mapActions, mapGetters } from 'vuex'
import { MARK_AS_TYPES } from '@js/mark-as-utils'

export default {
  data() {
    return {
      isErrorWatcher: false
    }
  },
  computed: {
    ...mapGetters('api', [
      'getErrorMessage'
    ]),
    markTasksAsLateError() {
      return this.getErrorMessage('task/markTasksAsLate')
    },
    markTasksAsFailedError() {
      return this.getErrorMessage('task/markTasksAsFailed')
    },
    markTasksAsCompletedError() {
      return this.getErrorMessage('task/markTasksAsCompleted')
    }
  },
  created() {
    if (this.isErrorWatcher) {
      this.watchErrorNotification('markTasksAsLateError')
      this.watchErrorNotification('markTasksAsFailedError')
      this.watchErrorNotification('markTasksAsCompletedError')
    }
  },
  methods: {
    ...mapActions('task', [
      'markTasksAsLateRequest',
      'markTasksAsFailedRequest',
      'markTasksAsCompletedRequest'
    ]),
    markTasksAs(tasks, option) {
      const taskIds = tasks.map(task => task.id)
      switch (option.type) {
        case MARK_AS_TYPES.LATE:
          this.markTasksAsLate(taskIds, option.lateBy)
          break
        case MARK_AS_TYPES.FAILED:
          this.$buefy.dialog.prompt({
            type: 'is-danger',
            inputAttrs: {
              required: false
            },
            message: this.$t('tasks.markAs.failedPrompt.message'),
            confirmText: this.$t('tasks.markAs.failedPrompt.confirmLabel'),
            onConfirm: (value) => this.markTasksAsFailed(taskIds, value)
          })
          break
        case MARK_AS_TYPES.COMPLETED:
          this.$buefy.dialog.prompt({
            inputAttrs: {
              required: false
            },
            message: this.$t('tasks.markAs.completedPrompt.message'),
            confirmText: this.$t('tasks.markAs.completedPrompt.confirmLabel'),
            onConfirm: (value) => this.markTasksAsCompleted(taskIds, value)
          })
          break
      }
    },
    markTasksAsLate(taskIds, lateBy) {
      this.addApiListeners('task/markTasksAsLate', this.onMarkTasksAsLateComplete)
      this.markTasksAsLateRequest({ taskIds, lateBy })
    },
    markTasksAsFailed(taskIds, comment = null) {
      this.addApiListeners('task/markTasksAsFailed', this.onMarkTasksAsFailedComplete)
      this.markTasksAsFailedRequest({ taskIds, comment })
    },
    markTasksAsCompleted(taskIds, comment = null) {
      this.addApiListeners('task/markTasksAsCompleted', this.onMarkTasksAsCompletedComplete)
      this.markTasksAsCompletedRequest({ taskIds, comment })
    },
    onMarkTasksAsLateComplete() {
      this.removeApiListeners('task/markTasksAsLate', this.onMarkTasksAsLateComplete)
      if (!this.markTasksAsLateError) {
        this.openToast({
          message: this.$t('tasks.markAs.lateSuccess'),
          type: 'is-success'
        })
      }
    },
    onMarkTasksAsFailedComplete() {
      this.removeApiListeners('task/markTasksAsFailed', this.onMarkTasksAsFailedComplete)
      if (!this.markTasksAsFailedError) {
        this.openToast({
          message: this.$t('tasks.markAs.failedSuccess'),
          type: 'is-success'
        })
      }
    },
    onMarkTasksAsCompletedComplete() {
      this.removeApiListeners('task/markTasksAsCompleted', this.onMarkTasksAsCompletedComplete)
      if (!this.markTasksAsCompletedError) {
        this.openToast({
          message: this.$t('tasks.markAs.completedSuccess'),
          type: 'is-success'
        })
      }
    }
  }
}
