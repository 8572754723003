import { formatDate } from '@js/utils'

export function getFulfilmentChannel(date, startTime, endTime, hubIds) {
  const formattedDate = formatDate(date, { zeroPadded: false })
  // properties should be in snake_case
  return {
    channel: 'FulfilmentChannel',
    serving_date: formattedDate,
    start_time: startTime,
    end_time: endTime,
    hub_ids: hubIds
  }
}
