import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      delay: 800
    }
  },
  computed: {
    ...mapGetters('filters', [
      'selectedDate',
      'selectedTimeRange',
      'selectedHubIds'
    ]),
    debouncer() {
      return debounce(this.apiRequest, this.delay)
    },
    areFiltersReady() {
      return this.selectedDate &&
        this.selectedTimeRange && this.selectedTimeRange.length === 2 &&
        this.selectedHubIds && this.selectedHubIds.length > 0
    }
  },
  watch: {
    selectedDate() {
      this.debounceApiRequest()
    },
    selectedTimeRange() {
      this.debounceApiRequest()
    },
    selectedHubIds() {
      this.debounceApiRequest()
    }
  },
  created() {
    this.debounceApiRequest()
  },
  methods: {
    debounceApiRequest() {
      if (this.areFiltersReady) {
        this.debouncer.cancel()
        this.debouncer()
      }
    },
    apiRequest() {
      throw new Error('apiRequest method needs to be overridden')
    }
  }
}
