import debounce from 'lodash/debounce'
import { getFulfilmentChannel } from '@js/actioncable-utils'

export default {
  channels: {
    FulfilmentChannel: {
      connected() {
        console.log('connected')
        this.onConnected()
      },
      rejected() {
        console.log('rejected')
        this.onRejected()
      },
      received(data) {
        console.log('received', data)
        this.onReceived(data)
      },
      disconnected() {
        console.log('disconnected')
        this.onDisconnected()
      }
    }
  },
  data() {
    return {
      isCableSubscribed: false,
      cableDebounceCallback: null,
      cableDebouncer: debounce(this.debouncedRequest, 1000)
    }
  },
  watch: {
    selectedDate() {
      this.updateFulfilmentChannelSubscription()
    },
    selectedTimeRange() {
      this.updateFulfilmentChannelSubscription()
    },
    selectedHubIds() {
      this.updateFulfilmentChannelSubscription()
    }
  },
  methods: {
    subscribeFulfilmentChannel() {
      if (!this.isCableSubscribed) {
        this.$cable.subscribe(getFulfilmentChannel(
          new Date(this.selectedDate),
          this.selectedTimeRange[0],
          this.selectedTimeRange[1],
          this.selectedHubIds
        ))
        this.isCableSubscribed = true
      }
    },
    updateFulfilmentChannelSubscription() {
      this.$cable.unsubscribe('FulfilmentChannel')
      this.isCableSubscribed = false
      this.subscribeFulfilmentChannel()
    },
    onConnected() {},
    onRejected() {},
    onReceived() {},
    onDisconnected() {},
    debounceRequest(callback) {
      this.cableDebouncer.cancel()
      this.cableDebouncer()
      this.cableDebounceCallback = callback
    },
    debouncedRequest() {
      if (this.cableDebounceCallback) {
        this.cableDebounceCallback()
        this.cableDebounceCallback = null
      }
    }
  }
}
